<template>
  <Modal :active="true" :title="titulo" size="normal" width="900px" @close="$emit('fechar')">
    <div :id="idPesquisa" :style="itens.length > 0 ? 'height: 62vh' : ''">
      <DataTable
        :id="`data-table-${idPesquisa}`"
        ref="datatable"
        custom-search
        :p-status="pStatus"
        :p-data-source="dataSource"
        :p-show-buttons="false"
        :p-data="itens"
        :p-hide-button-more="hideButtonMore"
        :p-quantidade-de-items="pQuantidadeDeItems"
        :p-sorted-by="pSortedBy"
        :next-page="nextPage"
        :id-div-utilizada-paginacao="idPesquisa"
        :no-delete="true"
        :custom-highlighters="customHighlighters"
        :custom-style="customStylePesquisa"
        @search="$emit('search', $event)"
      >
      <template v-slot:new-item>
            <Button v-if="botaoCadastro" :data-group="this.botaoCadastro.datagroup" class="button" @click="this.botaoCadastro.metodoCadastrar(null)">
              {{this.botaoCadastro.captionBotao}}
            </Button>
          </template>
      </DataTable>
    </div>
    <div v-if="itens.length === 0 && pStatus !== 'is-loading'">
      <DataTableEmpty />
    </div>
    <template #footer>
      <Button caption="Selecionar" type="primary" :disabled="podeSelecionar" @click="aoSelecionar" />
      <Button caption="Cancelar" :title="shortkeys.MSG_KEY_ESC" class="ml-1" @click="$emit('fechar')" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@alterdata/component-vue/Modal'
import DataTable from '@alterdata/component-vue/DataTable'
import Button from '@alterdata/component-vue/Button'
import DataTableEmpty from '@alterdata/component-vue/DataTable/DataTable.Empty.vue'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
export default {
  name: 'Pesquisa',
  components: { Modal, DataTable, Button, DataTableEmpty },
  mixins: [mixinFocoPrimeiroElementoDataTable, mixinPesquisa],
  props: {
    titulo: {
      type: String,
      required: true
    },
    propItens: {
      type: Array,
      required: true
    },
    modalVisivel: Boolean,
    propDataSource: {
      type: Array,
      required: true
    },
    pHideButtonMore: Boolean,
    pStatus: {
      type: String,
      default: ''
    },
    pQuantidadeDeItems: {
      type: Number,
      default: undefined
    },
    nextPage: Function,
    customStyle: {
      type: String,
      default: ''
    },
    metodoChecarSeItemNaoPodeSerSelecionado: {
      type: Function,
      default: () => false
    },
    customSearch: {
      type: Boolean,
      default: false
    },
    customHighlighters: {
      type: Function
    },
    pPages: {
      type: [String, Number]
    },
    pSortedBy: {
      type: String,
      default: ''
    },
    idPesquisa: {
      type: String,
      default: 'pesquisa'
    },
    botaoCadastro: {
      type: Object,
      default: null
    }
  },
  emits: ['search', 'carregar-item-selecionado', 'fechar'],
  data () {
    return {
      dataSource: {
        columns: [],
        itens: [],
        itensSelected: [],
        itensDisabled: [],
        loadMore: null,
        select: (item) => console.log('[select] ' + item),
        delete: async (item) => console.log('[delete] ' + item)
      },
      itens: this.propItens,
      shortkeys: shortkeys,
      avisoVisivel: false,
      textoAviso: '',
      hideButtonMore: this.pHideButtonMore
    }
  },
  computed: {
    podeSelecionar () {
      const { itensSelected } = this.dataSource
      const _metodoChecarSeItemNaoPodeSerSelecionado = this.metodoChecarSeItemNaoPodeSerSelecionado
        ? this.metodoChecarSeItemNaoPodeSerSelecionado(itensSelected)
        : false
      return !itensSelected || itensSelected.length === 0 || _metodoChecarSeItemNaoPodeSerSelecionado
    },
    customStylePesquisa () {
      const style = this.itens.length > 0 ? 'height: 80%' : ''
      return `${this.customStyle} ${style}`
    }
  },
  watch: {
    pDataSource: {
      immediate: true,
      handler () {
        this.dataSource = this.propDataSource
      }
    },
    pHideButtonMore: {
      immediate: true,
      handler () {
        this.hideButtonMore = this.pHideButtonMore
      }
    },
    pStatus: {
      immediate: true,
      handler () {
        this.status = this.pStatus
      }
    },
    propItens: {
      immediate: true,
      handler () {
        this.itens = this.propItens
        if (this.itens.length > 0) {
          this.focarNoPrimeiroElementoDataTable()
        } else {
          this.focarNaPesquisa()
        }
      }
    }
  },
  mounted () {
    this.dataSource.itensSelected = []
  },
  methods: {
    aoSelecionar () {
      const { itensSelected } = this.dataSource
      if (itensSelected) {
        this.$emit('carregar-item-selecionado', itensSelected)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@use '@alterdata/theme-bulma';
</style>
